import Link from 'next/link';
import IconTriangleDown from 'components/IconTriangleDown';
import useVisible from 'hooks/useVisible';
import AnimateHeight from 'components/AnimateHeight';
import { useEffect, useState } from 'react';
import { SubNav, Link as SanityLink, Navigation } from '../../../sanity/schema-types';

interface NavItemsProps {
  pathname: string;
  setIsVisible: (bool: boolean) => void;
  navSection: SubNav;
}

const NavItems = ({ pathname, setIsVisible, navSection }: NavItemsProps) => {
  return (
    <div className="bg-white p-16 pl-16 sm:pl-[80px] md:pl-[104px]">
      <ul className="m-0 list-none p-0 sm:flex">
        {navSection.subNavSectionBuilder?.map((m: SanityLink) => (
          <li key={m.title}>
            <Link href={`${m.href}`} passHref>
              <a
                onClick={() => setIsVisible(false)}
                className={`${pathname === m.href ? 'text-black' : 'text-gray-dark'} mr-16 text-sm`}
              >
                {m.title}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

interface NavSubProps {
  pathname: string;
  navigation: Navigation;
}

const NavSub = ({ pathname, navigation }: NavSubProps) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);
  const [navSection, setNavSection] = useState<SubNav>();

  useEffect(() => {
    setNavSection(navigation?.navigationBuilder?.find((section: SubNav) => pathname.includes(`${section.href}`)));
  }, [pathname]);

  if (navSection?.subNavSectionBuilder?.length) {
    return (
      <nav ref={ref}>
        <div className="sm:hidden">
          <button onClick={() => setIsVisible(!isVisible)} className={`btn-default-outline btn w-full bg-white`}>
            {pathname.split('/')[0]}
            Sub Menu <IconTriangleDown className={`${isVisible ? '' : 'rotate-90'} transition duration-300`} />
          </button>

          <AnimateHeight open={isVisible}>
            <NavItems pathname={pathname} setIsVisible={setIsVisible} navSection={navSection} />
          </AnimateHeight>
        </div>

        <div className="hidden sm:block">
          <NavItems pathname={pathname} setIsVisible={setIsVisible} navSection={navSection} />
        </div>
      </nav>
    );
  } else {
    return <></>;
  }
};

export default NavSub;
