import App, { AppContext } from 'next/app';
import LayoutMain from 'layouts/Main';
import Router from 'next/router';
import auth from 'utils/auth';
import bugsnagClient from 'services/bugsnag';
import ErrorComponent from './_error';
import { getPathFromUrl } from 'utils/utils';
import { fetchSEO } from 'services/seoApi';
import { getBundleBySlug, getProductBySlug } from 'services/productsApi';
import { Product, Bundle, SeoData } from 'types';
import { waitUntilGtagHasLoaded } from 'services/google';

import '../styles/globals.css';

const ErrorBoundary = bugsnagClient.getPlugin('react');

const identify = () => {
  if (window.gt.user && window.analytics) {
    window.analytics.identify(
      window.gt.user.id,
      {
        firstName: window.gt.user.firstName,
        lastName: window.gt.user.lastName,
        email: window.gt.user.email,
        phone: window.gt.user.phone,
        smsOptIn: window.gt.user.smsOptIn,
        primaryEventId: window.gt.user.primaryEventId || '',
        brand: 'menguin',
        emailOptIn: window.gt.user.emailOptIn,
        state: window.gt.user.state,
      },
      {
        // @ts-ignore
        All: true,
        Iterable: {
          phoneNumber: window.gt.user.phone,
        },
      }
    );
  }
};

const getSEO = async (pathname: string) => {
  if (pathname.includes('/collection/') && (pathname.match(/\//g) || []).length === 3) {
    try {
      const seo = await (await fetchSEO(pathname)).json();

      if (pathname.includes('tuxedos-and-suits')) {
        const bundleRes = await getBundleBySlug(pathname.split('/')[3]);

        if (bundleRes.status !== 200 && bundleRes.status !== 201) {
          throw bundleRes.statusText;
        }

        const bundle = (await bundleRes.json()) as Bundle;

        return seo.title === ''
          ? {
              title: `${bundle.display_name!} | Menguin`,
              metaDescription: `${bundle.display_name!} | ${bundle.short_description!}`,
            }
          : seo;
      } else {
        const productRes = await getProductBySlug(pathname.split('/')[3]);

        if (productRes.status !== 200 && productRes.status !== 201) {
          throw productRes.statusText;
        }

        const product = (await productRes.json()) as Product;

        return seo.title === ''
          ? {
              title: `${product.display_name!} | Menguin`,
              metaDescription: `${product.display_name!} | ${product.short_description!}`,
            }
          : seo;
      }
    } catch (e) {
      console.error(e);
    }
  }

  return await (await fetchSEO(pathname)).json();
};

const fetchSession = async () =>
  await fetch('/api/user/session', {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'same-origin',
  });

const configureAnalyticsReporting = async () => {
  try {
    await waitUntilGtagHasLoaded();

    gtag('config', process.env.NEXT_PUBLIC_GA4_MEASUREMENT_ID ?? '');
  } catch (e) {
    console.error(e);
  }
};

interface Props {
  canonicalUrl: string | null;
  pageProps: unknown;
  pathname: string;
  seo: SeoData;
}

export default class extends App<Props> {
  /**
   * Reruns on every /page component load
   */
  static async getInitialProps({ Component, ctx }: AppContext) {
    const canonicalUrl = ctx.asPath?.includes('/app')
      ? null
      : `https://www.menguin.com${(ctx.asPath || '').split('?')[0]}`;

    const props = {
      pathname: ctx.asPath,
      pageProps: {},
      // Pull SEO data from compiled SEO traits file (utils/seo)
      // or cms in Redis cache
      seo: await getSEO(getPathFromUrl(ctx.asPath!)),
      canonicalUrl,
    };

    if (Component.getInitialProps) {
      const pageProps = {
        ...(await Component.getInitialProps(ctx)),
      };
      return {
        ...props,
        pageProps,
      };
    }

    return {
      ...props,
    };
  }

  /**
   * All actions within componentDidMount happen client side only.
   * _app.tsx.ComponentDidMount will only mount once throughout the
   * lifecycle of the application. Think of this as a "bootstrap"
   * for initial js load within the entire application.
   */
  async componentDidMount() {
    const res = await fetchSession();

    const user = res.status === 200 ? await res.json() : null;

    user && auth.storeUser(user);
    window.organization = 'menguin';

    if (!user) {
      window.localStorage.removeItem('jwt');
    }

    /** Initialize Analytics on server load*/
    if (process.env.ENVIRONMENT !== 'production') {
      window.dataLayer = [];
    }

    identify();

    /**
     * This will create a callback that runs everytime
     * a new page is loaded via client side routing.
     * i.e. using <Link>
     */
    Router.events.on('routeChangeComplete', () => {
      window.analytics.page();
      identify();

      window.gt = {
        ...window.gt,
        user: user,
      };

      if (!user) {
        window.localStorage.removeItem('jwt');
      }
    });

    configureAnalyticsReporting();
  }

  render() {
    const { props } = this;
    const { Component, pageProps } = this.props;
    return (
      <ErrorBoundary FallbackComponent={ErrorComponent}>
        <LayoutMain {...props}>
          <Component {...pageProps} />
        </LayoutMain>
      </ErrorBoundary>
    );
  }
}
