import React, { Fragment } from 'react';
import Head from 'next/head';
import { SeoData } from 'types';

interface Props {
  seo: SeoData;
  pathname: string;
  canonicalUrl: string | null;
}

export default ({ seo, pathname, canonicalUrl }: Props) => (
  <Head>
    <meta charSet="utf-8" />
    <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
    <meta name="description" content={seo.metaDescription} />
    <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=0" />
    <meta name="google-site-verification" content="2ImMlGSn55uD9QtegsLxy5eiiu6RPkLVXVanT2_3sqw" />
    <title>{seo.title}</title>
    {/* establish early connections to third-party origins to avoid round-trips */}
    <link rel="dns-prefetch" href="//gentux.imgix.net" />

    {canonicalUrl && canonicalUrl !== null && <link rel="canonical" href={canonicalUrl} />}

    {/* Social sharing tags */}
    {pathname === '/' && (
      <Fragment>
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Menguin" />
        <meta property="og:description" content="Find high quality, fashionable tuxedo rentals for your special day." />
        <meta property="og:image" content="https://gentux.imgix.net/menguin-wedding-party-celebrating.jpg" />
        <meta name="twitter:site" content="@menguintux" />
        <meta name="twitter:card" content="summary_large_image" />
      </Fragment>
    )}
    <link rel="shortcut icon" type="image/x-icon" href="/favicon.ico" />
    <link rel="apple-touch-icon" sizes="180x180" href="/touch-icon.png" />
    <link rel="sitemap" type="application/xml" title="Sitemap" href="/sitemap" />
  </Head>
);
