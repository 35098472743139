import React from 'react';
import Link from 'next/link';
import bugsnagClient from 'services/bugsnag';
import { NextPageContext } from 'next';
import Line from 'components/Line';

interface Props {
  statusCode: number;
}

class Error extends React.Component<Props> {
  static getInitialProps({ res, err }: NextPageContext) {
    if (err) {
      bugsnagClient.notify(err);
    }
    const statusCode = res ? res.statusCode : err ? err.statusCode : null;
    return {
      statusCode,
      seo: {
        title: `${statusCode} Error | Menguin`,
        metaDescription: "Oops! We're not quite ready.",
      },
    };
  }

  render() {
    return (
      <div className="m-section-hero container flex flex-wrap items-center justify-around">
        <img
          id="page-not-found-img"
          alt="404"
          src="https://gentux.imgix.net/1550091090_404-error-image.png?auto=format"
          width="250px"
          height="485px"
        />

        <div className="mb-32">
          <h1 className="text-h1-display">{this.props.statusCode} Error</h1>

          <p>Oops! We're not quite ready.</p>

          <Line />

          <Link href="/">
            <a className=" btn-info btn">Go Home</a>
          </Link>
        </div>
      </div>
    );
  }
}

export default Error;
