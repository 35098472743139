import React from 'react';
import Link from 'next/link';
import useVisible from 'hooks/useVisible';
import { Navigation, SubNav } from '../../../sanity/schema-types';

const navItemClassnames = `
  text-h5-display
  normal-case
  py-4
  px-16
  flex
  items-center
  whitespace-nowrap
  no-underline
  text-gray-darker
  md:h-[100px]
  md:flex
  md:items-center
  hover:bg-gray-light
  hover:no-underline
`;

interface Props {
  pathname: string;
  navigation: Navigation;
}

const NavMain = ({ pathname, navigation }: Props) => {
  const { ref, isVisible, setIsVisible } = useVisible(false);

  return (
    <header>
      <nav>
        <div className="flex items-stretch justify-between bg-gray-lighter px-16 md:px-32">
          <Link href="/" passHref>
            <a className="flex items-center py-4 text-sm sm:py-16">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 41 40"
                style={{ enableBackground: 'new 0 0 41 40' } as React.CSSProperties}
                width={40}
                xmlSpace="preserve"
              >
                <path id="XMLID_21_" className="st0" d="M41,40H0V0h41V40z M3,37h35V3H3V37z" />
                <polygon
                  id="XMLID_19_"
                  className="st0"
                  points="20.7,20.6 12.8,11.3 11.9,11.3 11.9,28.7 14.5,28.7 14.5,17.2 20.6,24.4 20.7,24.4
                      22.4,22.5       "
                />
                <polygon
                  id="XMLID_18_"
                  className="st0"
                  points="28.6,11.3 22.9,18 24.5,19.9 26.9,17.2 26.9,28.7 29.5,28.7 29.5,11.4       "
                />
              </svg>
            </a>
          </Link>

          <div ref={ref} className="relative flex flex-grow items-stretch justify-start pl-16">
            <div
              onClick={() => setIsVisible(!isVisible)}
              className="flex cursor-pointer items-center p-16 hover:bg-gray-light md:hidden"
            >
              &#9776;
            </div>

            <ul
              className={`${
                isVisible ? '' : 'hidden'
              } absolute bottom-0 z-50 m-0 translate-y-[100%] list-none items-stretch bg-white p-32 shadow-3 md:relative md:flex md:translate-y-0 md:bg-transparent md:p-0 md:shadow-none`}
            >
              {navigation.navigationBuilder?.length &&
                navigation.navigationBuilder.reduce((previousVal: JSX.Element[], currentVal: SubNav) => {
                  if (!currentVal.hidden) {
                    return [
                      ...previousVal,
                      <li className="flex items-stretch" key={currentVal.title}>
                        <Link href={`${currentVal.href}`} passHref>
                          <a
                            onClick={() => setIsVisible(false)}
                            className={`${navItemClassnames} ${
                              pathname.includes(currentVal.href) && '!text-blue-dark'
                            }`}
                          >
                            {currentVal.title}
                          </a>
                        </Link>
                      </li>,
                    ];
                  }
                  return previousVal;
                }, [])}
            </ul>

            <div className="hidden items-stretch xs:flex md:hidden">
              <Link passHref href="/collection/[category]" as="/collection/tuxedos-and-suits">
                <a className={`${navItemClassnames} ${pathname.includes('/collection/') && 'text-blue-dark'}`}>
                  Collection
                </a>
              </Link>
            </div>
          </div>
          <div className="flex">
            <ul className="flex list-none items-stretch space-x-16">
              <li className="flex items-stretch">
                <a href={`${process.env.NEXT_PUBLIC_APP_URL}/account/login`} className="flex items-center text-black">
                  Login
                </a>
              </li>
              <li className="flex items-stretch">
                <a href={`${process.env.NEXT_PUBLIC_APP_URL}/signup/email`} className="flex items-center text-black">
                  Sign Up
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default NavMain;
